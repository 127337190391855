const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://2l9hzv1zel.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://ubfd5ongb1.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://w0ii35o49j.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;